import React from 'react';
import Layout from '../components/layout/Layout';
import IndexStickyHeader from '../components/IndexStickyHeader';
import MdCardList from '../components/MdCardsList';
import Header from './layout/Header';
import GenericPageTopHeader from './GenericPageTopHeader'
import { startCase, upperFirst, find, get, filter } from 'lodash';
import { useMediaQuery } from 'react-responsive';

const GenericPageWithMdSections = ({pageContext}) => {
  const { nodes } = pageContext;

  const isMobilePanelView = useMediaQuery({
    query: '(max-device-width: 600px)'
  });

  const customHeader = find(nodes, (node) => node.frontmatter.type === 'header')

  // looks for type: header (markdown) file for the page, if not plugs in default values from the sections
  const pageTitle = get(customHeader, 'frontmatter.title', startCase(upperFirst(nodes[0].frontmatter.page)));
  const userDefinedBgColorClass = get(customHeader, 'frontmatter.bgHeaderColor', find(nodes, (node) => node.frontmatter.bgHeaderColor))
  const headerSubContent = get(customHeader, 'html')

  // these are the page sections under the header, we filter out the header page from the sections
  // before we build the page
  const sectionNodes = filter(nodes, (node) => node.frontmatter.type !== 'header')

  // again we just take the first on in class, due to tailwind requiring
  // the full class name we actually have to pass in the class `bg-{class-name-from-tailwind-config}`
  const bgColorClass = userDefinedBgColorClass || 'headerred';

  return (
    <Layout>
      { !isMobilePanelView && <IndexStickyHeader nodes={sectionNodes} /> }
      <GenericPageTopHeader pageTitle={pageTitle} bgColorClass={bgColorClass} subContent={headerSubContent}/>
      <MdCardList nodes={sectionNodes} />
    </Layout>
  );
};

export default GenericPageWithMdSections;
